// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
    apiKey: "AIzaSyC1W1WyclzghIn85sSaHGSDmONGmjIuoAg",
    authDomain: "thedevpiyush-portfolio.firebaseapp.com",
    projectId: "thedevpiyush-portfolio",
    storageBucket: "thedevpiyush-portfolio.firebasestorage.app",
    messagingSenderId: "1041208056946",
    appId: "1:1041208056946:web:994b07f24e9625b43eb6eb"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);